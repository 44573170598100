import React from 'react'
import {withRouter} from 'react-router';
import {List, ListItem, Snackbar} from '@material-ui/core';
// import MuiAlert from '@material-ui/lab/Alert';
import app from "../firebase/firebaseConfig";

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

class Device extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: {},
            visible: false,
            currentTab: 'general',
            deviceList: []
        }
    }

    componentDidMount = () => {
        const userID = app && app.auth().currentUser && app.auth().currentUser.uid
        this.getUserHousesData(userID);
    }

    getUserHousesData = (userID) => {
        if (!userID) return
        // var allData = {}
        // let self = this
        app.database().ref(`Users/${userID}/hubAccess`).on('value', (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const data = dataSnapshot.val();
                this.setState({houseAccess: data})
                let accessKeys = Object.keys(data)
                let houseList = []
                for (let i = 0; i < accessKeys.length; i++) {
                    app.database().ref(`HubData/${accessKeys[i]}`).on('value', (nodeDataSnapshot) => {
                        if (nodeDataSnapshot.exists()) {
                            let obj = {
                                ...nodeDataSnapshot.val()['hubDetails'],
                                id: accessKeys[i]
                            }
                            houseList.push(obj)
                            this.setState({houseList})
                        }
                    })
                }
                console.log('----------', houseList)
            }
        })
    }

    handleTabsChange = (event, value) => {
        this.setState({currentTab: value});
    };

    tabs = [
        {value: 'general', label: 'General'},
        // { value: 'subscription', label: 'Subscription' },
        // { value: 'notifications', label: 'Notifications' },
        {value: 'security', label: 'Security'}
    ];

    generate = (element) => {
        return [0, 1, 2].map((value) =>
            React.cloneElement(element, {
                key: value,
            }),
        );
    }
    handlerConnect = (item) => {
        let {houseList} = this.state
        this.setState({visible: true})
        const userID = app && app.auth().currentUser && app.auth().currentUser.uid
        let self = this
        app.database().ref(`hubs/${item.id}`).on('value', (nodeDataSnapshot) => {
            if (nodeDataSnapshot.exists()) {
                let obj = nodeDataSnapshot.val()['hubDetails']
                if (obj.onlineStatus === 'Online') {
                    this.setState({open: true})
                    setTimeout(function () {
                        self.setState({open: false})
                    }, 3000)
                }
                houseList.forEach((val, index) => {
                    if (item.id === val.id) {
                        item.onlineStatus = obj.onlineStatus
                    }
                })
                this.setState({houseList})
            }
        })
        console.log('=====', userID)
    }

    render() {
        let {deviceList, visible, houseList, open} = this.state
        return (
            <div>
                {/* <Snackbar open={visible} autoHideDuration={2000}>
                    <Alert severity="success">Connecting please wait!</Alert>
                </Snackbar> */}
                <Snackbar
                    anchorOrigin={{vertical: "center", horizontal: "center"}}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    message={<span style={{textAlign: "center"}}>Device has connected successfully!</span>}
                    key={'001'}
                    severity="success"
                />
                <List dense={false} style={{paddingTop: 0}}>
                    <ListItem style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#000',
                        color: "#fff"
                    }}>
                        <span>Device Status</span>
                        <span>Device Name</span>
                        <span>Device Action</span>
                    </ListItem>
                    {
                        houseList && houseList.map((item, index) => {
                            return <ListItem style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: "#f5f5f5",
                                marginBottom: 2
                            }}>
                                {/* <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar> */}
                                <p style={{
                                    textAlign: 'left',
                                    width: 60,
                                    margin: 5,
                                    color: item.onlineStatus === "Online" ? "#8a85ff" : 'red'
                                }}>{item.onlineStatus}</p>
                                <p style={{flex: 1, textAlign: 'center', margin: 5}}>{item.hubName}</p>
                                <p style={{textAlign: 'center', width: 60, margin: 5}}>
                                    <a style={{color: "#008ffb"}} onClick={() => this.handlerConnect(item)}>Connect</a>
                                </p>
                                {/* <ListItemText
                                    primary={item.name}
                                    secondary={true ? item.describetion : null}
                                /> */}
                                {/* <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon color="#000"/>
                                </IconButton> */}
                            </ListItem>
                        })
                    }
                </List>
            </div>
        )
    }
}

export default withRouter(Device)
