import React from 'react'
import {Box, Button, Card, Grid, CardContent, CardHeader, TextField, Divider,} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import app from '../../../firebase/firebaseConfig'
import countries from './countries';
import {Redirect} from 'react-router';

class GeneralDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            redirect: false
        }
    }

    componentDidMount = () => {
        const user = app.auth().currentUser
        app.database().ref(`Users/${user.uid}/userInfo`).once('value', (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                this.setState({user: {...this.state.user, phoneNumber: dataSnapshot.val().phoneNumber}})
                if (dataSnapshot.val().address) {
                    this.setState({user: {...this.state.user, city: dataSnapshot.val().address.split(', ')[0]}})
                    this.setState({user: {...this.state.user, state: dataSnapshot.val().address.split(', ')[1]}})
                    this.setState({user: {...this.state.user, country: dataSnapshot.val().address.split(', ')[2]}})
                }
            }
        })
        const userDetails = {}
        userDetails.avatar = user.photoURL
        userDetails.name = user.displayName
        userDetails.email = user.email
        userDetails.phoneNumber = user.phoneNumber
        this.setState({user: userDetails})
    }

    handleUpdate = () => {
        const that = this

        app.auth().currentUser.updateProfile({
            displayName: this.state.user.name,
            photoURL: this.state.user.avatar,
        }).then(function () {
            let userID = app.auth().currentUser.uid
            app.database().ref(`Users/${userID}/userInfo`).update({
                'address': `${that.state.user.city}, ${that.state.user.state}, ${that.state.user.country}`,
                phoneNumber: `${that.state.user.phoneNumber}`
            })
            window.location.reload(false);
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/"/>;
        }
        if (this.state.user) {
            return (
                <Card>
                    <CardHeader title='Profile'/>
                    <Divider/>

                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                                <TextField fullWidth label="Name" name="name"
                                           onChange={(e) =>
                                               this.setState({user: {...this.state.user, name: e.target.value}})}
                                           value={this.state.user.name !== 'undefined' ? this.state.user.name : null}
                                           variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField fullWidth label="Email Address" name="email" type="email"
                                           value={this.state.user.email !== "undefined" ? this.state.user.email : null}
                                           disabled variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField fullWidth label="Phone Number" name="phone"
                                           onChange={(e) => this.setState({
                                               user: {
                                                   ...this.state.user,
                                                   phoneNumber: e.target.value
                                               }
                                           })}
                                           value={this.state.user.phoneNumber || ''} variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Autocomplete
                                    options={countries}
                                    getOptionLabel={(option) => option.text}
                                    value={this.state.user.country}
                                    onChange={(e, option) => this.setState({
                                        user: {
                                            ...this.state.user,
                                            country: option.text
                                        }
                                    })}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            label={this.state.user.country ? this.state.user.country : "Country"}
                                            variant="outlined"

                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="State/Region"
                                    name="state"
                                    onChange={(e) => this.setState({user: {...this.state.user, state: e.target.value}})}
                                    value={this.state.user.state || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="city"
                                    onChange={(e) => this.setState({user: {...this.state.user, city: e.target.value}})}
                                    value={this.state.user.city || ''}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider/>
                    <Box p={2} display="flex" justifyContent="flex-end">
                        <Button color="secondary" onClick={this.handleUpdate} type="submit" variant="contained">
                            Save Changes
                        </Button>
                    </Box>
                </Card>
            )
        } else {
            return null
        }
    }
}

export default GeneralDetails
