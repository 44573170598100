import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBczwvKeESYf4HsyXOZy8a80DJWGVm4Eig",
    authDomain: "swift-hub-cloud-302220.firebaseapp.com",
    databaseURL: "https://swift-hub-cloud-302220-default-rtdb.firebaseio.com",
    projectId: "swift-hub-cloud-302220",
    storageBucket: "swift-hub-cloud-302220.appspot.com",
    messagingSenderId: "548950921657",
    appId: "1:548950921657:web:e4bcb7ef3b74154e19a668",
    measurementId: "G-HDWHM8X84R"
  };

const app = firebase.initializeApp(firebaseConfig);
export default !firebase.apps.length ? app : firebase.app();