import React from 'react'
import {withRouter} from 'react-router';
import {Breadcrumbs, ButtonBase, Card, Typography} from "@material-ui/core";
import app from "../firebase/firebaseConfig";
import MUIDataTable from "mui-datatables";


class MyHouses extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            houseAccess: null,
            rows: {},
            tableData: [],
            table: null,
            columns: []
        }
    }

    componentDidMount = () => {
        const userID = app.auth().currentUser.uid
        this.getUserHousesData(userID);
        localStorage.setItem('hidx', null)
    }

    getUserHousesData = (userID) => {
        app.database().ref(`Users/${userID}`).on('value', (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const data = dataSnapshot.val();
                this.setState({houseAccess: data.hubAccess})
                let accessKeys = data && data.hubAccess && Object.keys(data.hubAccess)
                if (!accessKeys || accessKeys.length === 0) return
                for (let i = 0; i < accessKeys.length; i++) {
                    app.database().ref(`HubData/${accessKeys[i]}`).on('value', (nodeDataSnapshot) => {
                        if (nodeDataSnapshot.exists()) {
                            const hubData = nodeDataSnapshot.val()
                            const hubName = hubData['hubDetails'].hubHostname
                            const status = hubData['hubDetails'].onlineStatus
                            const rows = this.state.rows
                            console.log(hubData.sensorData)
                            let sensorData = (hubData.sensorData && hubData.sensorData[0]) || {}
                            let aqi = sensorData && sensorData['aqi'] && sensorData['aqi'].value
                            let temp = sensorData && sensorData['temperature'] && sensorData['temperature'].value
                            let humid = sensorData && sensorData['humidity'] && sensorData['humidity'].value
                            rows[accessKeys[i]] = {i, hubName, status, temp, humid, aqi};
                            this.setState({rows: rows})
                            this.createTableData(rows)
                        }
                    })
                }
            }
        })
    }


    createTableData = (data) => {
        this.setState({columns: ["House Name", "Status", "Temperature (°C)", "Humidity (%)", "AQI (μg/m³)"]})
        const newData = Object.keys(data).map(key => data[key])
            .map(item => [item.hubName, item.status, Number(item.temp).toFixed(2),
                Number(item.humid).toFixed(2), Number(item.aqi).toFixed(2)])
        this.setState({tableData: newData})
    }

    render() {
        let {houseAccess} = this.state
        return (
            <div style={{height: 'calc(100vh - 105px)'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color: '#e6e5e8'}}>
                    <Typography variant='caption' color="inherit">My Houses</Typography>
                </Breadcrumbs>
                {this.state.houseAccess ? <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <div style={{display: 'flex', flex: 1, alignItems: 'flex-start', minHeight: '30vh', gap: 10}}>
                            {Object.keys(houseAccess).map((key, index) => {
                                    let hubName = houseAccess[key].hubDetails && houseAccess[key].hubDetails.hubHostname || ''
                                    let hubAddress = houseAccess[key].hubDetails && houseAccess[key].hubDetails.hubAddress || ''
                                    return <Card component={ButtonBase} key={key}
                                                 style={{
                                                     borderRadius: 5,
                                                     display: 'flex',
                                                     flex: 1,
                                                     padding: 10,
                                                     minWidth: 230,
                                                     maxWidth: 400,
                                                     minHeight: 100,
                                                     flexDirection: 'column',
                                                     backgroundColor: '#282C34'
                                                 }}
                                                 onClick={() => {
                                                     localStorage.setItem('hidx', Object.keys(this.state.houseAccess)[index]);
                                                     this.props.history.push('myhouses/data')
                                                 }}>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            width: '100%',
                                            flexDirection: 'column',
                                            color: '#e6e5e8'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flex: 1,
                                                gap: 40
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                     viewBox="0 0 402.771 402.138">
                                                    <path id="_001-smarthouse" data-name="001-smarthouse"
                                                          d="M312.777,387.237A15.707,15.707,0,1,1,297.07,371.53,15.707,15.707,0,0,1,312.777,387.237ZM86.59,371.558a15.725,15.725,0,0,1-15.707-15.707V162.266L201.254,43.33,331.626,162.266v56.662a160.746,160.746,0,0,1,31.415,10.359V190.925l18.3,16.691,21.172-23.208L201.255.807,0,184.407l21.172,23.208,18.3-16.691V355.851A47.152,47.152,0,0,0,86.59,402.945H250.532l-31.381-31.387ZM297.07,306.344c-18.952,0-39.463,8.77-53.595,22.888l-11.113,11.1,22.2,22.225,11.113-11.1c8.33-8.323,21.41-13.7,32.178-13.7,10.642,0,22.908,5.377,31.238,13.7l11.113,11.1,22.2-22.225-11.113-11.1c-14.134-14.119-35.4-22.888-54.225-22.888Zm94.626-19.5c-25.777-25.919-59.2-40.193-94.113-40.192s-68.344,14.275-94.114,40.194l-11.076,11.139,22.277,22.15L225.745,309c19.831-19.944,45.343-30.928,71.838-30.929s52,10.983,71.839,30.93L380.5,320.138l22.275-22.153Z"
                                                          transform="translate(0 -0.807)" fill="#8a85ff"/>
                                                </svg>
                                                <Typography variant='h5' key={key}>{hubName}</Typography>
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Typography variant='caption' key={key}>{hubAddress}</Typography>
                                            </div>
                                        </div>
                                    </Card>

                                }
                            )}
                        </div>
                        <MUIDataTable
                            title={"House List"}
                            data={this.state.tableData}
                            columns={this.state.columns}
                            // options={option}
                        />
                    </div>
                    : <div>No House Data</div>}


            </div>
        )
    }
}

export default withRouter(MyHouses)
