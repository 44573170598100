import React from 'react'
import { withRouter} from 'react-router';
import StepInstall from './widget/StepInstall';
import StepEnablement from './widget/StepEnablement';
import StepConnection from './widget/StepConnection';

class Guidance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'general',
            currentStep: 0
        }
    }

    handleTabsChange = (event, value) => {
        this.setState({ currentTab: value });
    };

    tabs = [
        { value: 'general', label: 'General' },
        // { value: 'subscription', label: 'Subscription' },
        // { value: 'notifications', label: 'Notifications' },
        { value: 'security', label: 'Security' }
    ];


    render() {
        // 设备安装 设备启动 设备连接 测试连接
        let { currentStep } = this.state
        console.log('------',currentStep)
        return (
            <div style={{width:'100vw'}}>
                <div className="swift-guidance"/>
                {
                    currentStep === 0 ? <StepInstall onChangeStep={() => this.setState({currentStep: 1})}/> : null
                }
                {
                    currentStep === 1 ? <StepEnablement onChangeStep={(currentStep) => this.setState({currentStep})}/> : null
                }
                {
                    currentStep === 2 ? <StepConnection onChangeStep={(currentStep) => this.setState({currentStep})}/> : null
                }
            </div>
        )
    }
}

export default withRouter(Guidance)
