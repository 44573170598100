import React from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import MarkerIcon from '../../Group 53.svg'
import './map.css'
import app from '../../firebase/firebaseConfig'
import {Card, CardHeader, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

// import { withRouter } from 'react-router-dom'


const containerStyle = {
    width: '100%',
    // height: '400px'
}

class MyComponent extends React.Component {
    _isMounted = false;
    lister = []

    constructor(props) {
        super(props)
        this.state = {
            LoadMap: false,
            sensorData: {},
            selectedMarker: {},
            markerID: null,
            lister: [],
            alert: false,
            alertMsg: null,
            severity: null,
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
        console.log('isMounted', this._isMounted, "props", this.props.data)
        let dataKeys = Object.keys(this.props.data)
        this.lister = dataKeys.map((key, index) => {
            return {
                [`hubs/${key}/sensorData`]: app.database()
                    .ref(`HubData/${key}/sensorData`)
                    .on('value', (snapShot) => {
                        if (snapShot.exists()) {
                            let dataSnap = snapShot.val()
                            if (index === dataKeys.length - 1) {
                                this.setState({LoadMap: true})
                            }
                            let upData = {[snapShot.ref.parent.key]: {}}
                            let newData = upData[snapShot.ref.parent.key]
                            newData.aqi = dataSnap[0].aqi && dataSnap[0].aqi.value
                            newData.temp = dataSnap[0].temperature && dataSnap[0].temperature.value
                            newData.humid = dataSnap[0].humidity && dataSnap[0].humidity.value
                            if (this._isMounted) {
                                this.setState({
                                    sensorData: {
                                        ...this.state.sensorData,
                                        [snapShot.ref.parent.key]: newData
                                    }
                                })
                            }
                        }
                    })
            };
        })
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        for (let i = 0; i < this.lister.length; i++) {
            let key = Object.keys(this.lister[i])
            app.database().ref(`${key}`).off('value')
        }

        this._isMounted = false
        this.setState({sensorData: null, selectedMarker: null, markerID: null})
    }


    render() {
        if (this._isMounted) {
            return (
                <LoadScript googleMapsApiKey="AIzaSyBczwvKeESYf4HsyXOZy8a80DJWGVm4Eig">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        options={{
                            styles: [
                                {elementType: "geometry", stylers: [{color: "#242f3e"}]},
                                {elementType: "labels.text.stroke", stylers: [{color: "#242f3e"}]},
                                {elementType: "labels.text.fill", stylers: [{color: "#746855"}]},
                                {
                                    featureType: "administrative.locality",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#d59563"}]
                                },
                                {
                                    featureType: "poi",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#d59563"}]
                                },
                                {
                                    featureType: "poi.park",
                                    elementType: "geometry",
                                    stylers: [{color: "#263c3f"}]
                                },
                                {
                                    featureType: "poi.park",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#6b9a76"}]
                                },
                                {
                                    featureType: "road",
                                    elementType: "geometry",
                                    stylers: [{color: "#38414e"}]
                                },
                                {
                                    featureType: "road",
                                    elementType: "geometry.stroke",
                                    stylers: [{color: "#212a37"}]
                                },
                                {
                                    featureType: "road",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#9ca5b3"}]
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "geometry",
                                    stylers: [{color: "#746855"}]
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "geometry.stroke",
                                    stylers: [{color: "#1f2835"}]
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#f3d19c"}]
                                },
                                {
                                    featureType: "transit",
                                    elementType: "geometry",
                                    stylers: [{color: "#2f3948"}]
                                },
                                {
                                    featureType: "transit.station",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#d59563"}]
                                },
                                {
                                    featureType: "water",
                                    elementType: "geometry",
                                    stylers: [{color: "#17263c"}]
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.fill",
                                    stylers: [{color: "#515c6d"}]
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.stroke",
                                    stylers: [{color: "#17263c"}]
                                }
                            ]
                        }}
                        streetViewControl={false}
                        center={{
                            lat: this.props.data[Object.keys(this.props.data)[0]].coordinates[0],
                            lng: this.props.data[Object.keys(this.props.data)[0]].coordinates[1]
                        }}
                        zoom={4.5}
                    >
                        {
                            Object.keys(this.props.data).map((hubKey, index) => {
                                return (
                                    <Marker
                                        icon={MarkerIcon}
                                        // label={this.props.data[hubKey].name}
                                        onClick={() => this.setState({
                                            selectedMarker: {
                                                [hubKey]: {
                                                    lat: this.props.data[hubKey].coordinates[0],
                                                    lng: this.props.data[hubKey].coordinates[1],
                                                    name: this.props.data[hubKey].name
                                                }
                                            }, markerID: hubKey
                                        })}
                                        key={index}
                                        position={{
                                            lat: this.props.data[hubKey].coordinates[0],
                                            lng: this.props.data[hubKey].coordinates[1]
                                        }}>

                                    </Marker>
                                )
                            })
                        }
                        {this.state.selectedMarker[this.state.markerID] &&
                        <InfoWindow
                            position={this.state.selectedMarker[this.state.markerID]}
                            visible={true}
                            clickable={true}
                            onCloseClick={() => this.setState({selectedMarker: {}, markerID: null})}
                            options={{
                                boxStyle: {
                                    backgroundColor: '#282c34',
                                    minWidth: '100px',
                                    padding: '0'
                                }
                            }}
                        >
                            {this.state.sensorData[this.state.markerID] ?
                                <Card>
                                    <CardContent>
                                        <Typography
                                            variant={'h4'}>{this.state.selectedMarker[this.state.markerID].name}</Typography>
                                        <Typography>Temperature: {this.state.sensorData[this.state.markerID].temp} C</Typography>
                                        <Typography>Humidity: {this.state.sensorData[this.state.markerID].humid} %</Typography>
                                        <Typography>AQI: {this.state.sensorData[this.state.markerID].aqi} µg/m3</Typography>
                                    </CardContent>
                                </Card>
                                // <div style={{color: 'black'}}>
                                //     <p>Temperature: {this.state.sensorData[this.state.markerID].temp} C</p>
                                //     <p>Humidity: {this.state.sensorData[this.state.markerID].humid} %</p>
                                //     <p>AQI: {this.state.sensorData[this.state.markerID].aqi} µg/m3</p>
                                // </div>
                                :
                                <div>Loading</div>
                            }
                        </InfoWindow>
                        }
                    </GoogleMap>
                </LoadScript>
            )
        } else {
            return null
        }
    }
}

export default React.memo(MyComponent)
