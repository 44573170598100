import React from 'react'
import {withRouter} from 'react-router';
import {Link, Breadcrumbs, Typography, Card, ButtonBase, SvgIcon} from '@material-ui/core';
import app from "../../firebase/firebaseConfig";
// import {readString} from 'react-papaparse';
import LineCharts from './LineCharts';
import MultiLineChart from './multiLineChart';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
// import ReactHlsPlayer from "react-hls-player";
// import ReactPlayer from 'react-player'
import moment from 'moment'
// import axios from 'axios'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {ReactComponent as AQIIcon} from "../../Logos/AQI.svg"
import {ReactComponent as TemperatureIcon} from "../../Logos/temperature.svg"
import {ReactComponent as HumidityIcon} from "../../Logos/humidity.svg"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const cameraHost = "https://swifthub.asia/hls"

class HouseData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hubData: null,
            Logs: null,
            label: [],
            timeList: [],
            chartData: [],
            sensorKey: "",
            sensorName: "",
            loadChart: false,
            popOpen: false,
            selectedDate: new Date(),
            chart1Lables: [],
            chart1Data: []
        }
    }

    componentDidMount = () => {
        var hubID = localStorage.getItem('hidx')
        this.updateCameraStream(hubID, '1')
    }

    componentWillUnmount() {
        // var hubID = localStorage.getItem('hidx')
        // this.updateCameraStream(hubID)
    }

    updateCameraStream = (hubID, status = "0") => {
        let {hubData} = this.state;
        // let data = {
        //     status: status,
        //     hubId: hubID
        // }
        // axios
        // axios.put('https://swifthub.asia/api/hub/stream/camera/',data).then(res=>{
        app.database().ref(`HubData/${hubID}`).on('value', (snapShot) => {
            console.log('-----value-----', snapShot.val())
            if (snapShot.exists()) {
                hubData = snapShot.val()
                this.setState({hubData})
            }
        })
        // }).catch(err=>{
        //     console.log(err)
        // })
    }
    updateChartData = (day, sensorName, sensorType, sensorId) => {
        const hubID = localStorage.getItem('hidx')
        let self = this
        app.database().ref(`/SensorData/${hubID}/${day}`).on("value", function (snapShot) {
            let sensorData = snapShot.val()
            if (!sensorData) {
                self.setState({chartData: [], timeList: []})
                return
            }
            let timeList = Object.keys(sensorData)
            let chartData = []

            timeList.forEach((key, index) => {
                chartData.push(sensorData[key])
            })
            self.setState({chartData, timeList})
        })
    }
    handleClickOpen = (sensorId, sensorName, sensorType) => {
        let day = moment().format("YYYYMMDD")
        this.setState({sensorId, sensorType, sensorName}, function () {
            this.updateChartData(day, sensorName, sensorType, sensorId)
            this.setState({popOpen: true})
        })
    }

    updateCameraChartData = (filename, cameraName, Type) => {
        const hubID = localStorage.getItem('hidx')
        let day = moment().format("YYYYMMDD")
        let self = this
        app.database().ref(`/CameraData/${hubID}/${day}`).on("value", function (snapshot) {
            let cameraData = snapshot.val()
            if (!cameraData) {
                self.setState({chartData: [], timeList: []})
                return
            }
            let timeList = Object.keys(cameraData)
            let chartData = []

            timeList.forEach((key, index) => {
                chartData.push(cameraData[key])
            })
            console.log(timeList)
            self.setState({chartData, timeList})
        })
    }

    handleCameraClick = (cameraName, type) => {
        let day = moment().format("YYYYMMDD")
        localStorage.setItem("sensorName", cameraName);
        localStorage.setItem("sensorType", type);
        this.updateCameraChartData(day, cameraName, type)
        this.setState({popOpen: true})
    }
    handleClose = () => {
        localStorage.setItem("sensorName", "")
        localStorage.setItem("sensorType", "")
        localStorage.setItem("sensorId", "")
        this.setState({popOpen: false, chartData: [], label: [], time: [], chart1Lables: [], chart1Data: []})
    }

    handleDateChange = (date) => {
        let {sensorName, sensorType, sensorId} = this.state
        let selectedDate = new Date(date)
        this.setState({selectedDate})
        let day = selectedDate && moment(selectedDate).format("YYYYMMDD")
        if (sensorType === 'camera') {
            this.updateCameraChartData(day, sensorName, sensorType)
        } else {
            this.updateChartData(day, sensorName, sensorType, sensorId)
        }

    }

    render() {
        let {hubData, timeList} = this.state
        // let {sensorName, sensorType, sensorId} = this.state
        if (hubData) {
            return (
                <div style={{minHeight: 'calc(100vh - 105px)',}}>
                    <Dialog PaperProps={{
                        style: {
                            backgroundColor: '#1c2025',
                            boxShadow: "none"
                        },
                    }} fullScreen open={this.state.popOpen} onClose={this.handleClose} TransitionComponent={Transition}>

                        <AppBar style={{position: 'relative'}}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6">
                                    Logs
                                </Typography>

                            </Toolbar>
                        </AppBar>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 20,
                            marginLeft: "10%",
                            marginRight: "10%"
                        }}>
                            <div style={{display: "flex"}}>
                                <MuiPickersUtilsProvider utils={MomentUtils} justifyContent='flex-end'>
                                    <Grid container>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/DD/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Date picker inline"
                                            value={this.state.selectedDate}
                                            onChange={this.handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <Card style={{display: 'flex',}}>
                                {localStorage.getItem('sensorType') === "camera" ? <MultiLineChart
                                        data={{"time": timeList, "data": this.state.chartData, "label": ""}}/> :
                                    <LineCharts data={{
                                        key: this.state.sensorId,
                                        "time": timeList,
                                        "data": this.state.chartData,
                                        "label": this.state.sensorName
                                    }}/>}
                            </Card>
                        </div>
                    </Dialog>
                    <Breadcrumbs aria-label="breadcrumb" separator="›" style={{color: '#e6e5e8'}}>
                        <Link color="inherit" href="/#/myhouses">
                            <Typography variant='caption'>My Houses</Typography>
                        </Link>
                        <Typography
                            variant='caption'>{hubData && hubData.hubDetails ? hubData.hubDetails.hubHostname : null}</Typography>
                    </Breadcrumbs>
                    <div style={{display: 'flex', flex: 1, width: '100%', gap: 20, flexDirection: 'column',}}>
                        <div style={{display: 'flex', flex: 1, gap: 20,}}>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 20, flex: 1}}>
                                {hubData.sensorData.map((item, index) => {
                                    let temperature = item.temperature || {}
                                    temperature.value = Number(temperature.value) || 0
                                    return <Card onClick={() => this.handleClickOpen("temperature", "Temperature")}
                                                 component={ButtonBase} key={index} variant='outlined' style={{
                                        display: 'flex',
                                        padding: 20,
                                        borderRadius: 5,
                                        flex: 1,
                                        gap: 20,
                                        minHeight: 110,
                                        maxHeight: 120,
                                        color: '#e6e5e8',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#282C34'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            textAlign: 'left'
                                        }}>
                                            <Typography variant='h5'>{temperature.name} </Typography>
                                            <Typography
                                                variant='h3'>{temperature && temperature.value ? temperature.value.toFixed(2) : null} °C</Typography>
                                        </div>
                                        <SvgIcon fontSize={"large"} component={TemperatureIcon} viewBox="0 0 67 161"/>
                                    </Card>

                                })}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 20, flex: 1}}>
                                {hubData.sensorData.map((item, index) => {
                                        let humidity = item.humidity || {}
                                        humidity.value = Number(humidity.value) || 0
                                        return <Card onClick={() => this.handleClickOpen("humidity", "Humidity", "")}
                                                     component={ButtonBase} variant='outlined' key={index} style={{
                                            display: 'flex',
                                            padding: 20,
                                            borderRadius: 5,
                                            flex: 1,
                                            gap: 20,
                                            minHeight: 110,
                                            maxHeight: 120,
                                            color: '#e6e5e8',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#282C34'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                textAlign: 'left'
                                            }}>
                                                <Typography variant='h5'>{humidity.name}</Typography>
                                                <Typography
                                                    variant='h3'>{humidity && humidity.value ? item.humidity.value.toFixed(2) : null}%</Typography>
                                            </div>
                                            <SvgIcon fontSize={"large"} component={HumidityIcon} viewBox="0 0 79 124"/>
                                        </Card>
                                    }
                                )}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 20, flex: 1}}>
                                {hubData.sensorData.map((item, index) =>
                                    <Card onClick={() => this.handleClickOpen("pm25", "PM", "")} component={ButtonBase}
                                          variant='outlined' key={index} style={{
                                        display: 'flex',
                                        padding: 20,
                                        borderRadius: 5,
                                        flex: 1,
                                        gap: 20,
                                        minHeight: 110,
                                        maxHeight: 120,
                                        color: '#e6e5e8',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#282C34'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                        }}>
                                            <Typography
                                                variant='h5'>{item && item.aqi ? item.aqi.aqiName : null}</Typography>
                                            <Typography
                                                variant='h3'>{item && item.aqi && item.aqi.value ? Number(item.aqi.value).toFixed(2) : null}</Typography>
                                        </div>
                                        <SvgIcon fontSize={"large"} component={AQIIcon} viewBox="0 0 119 100"/>
                                    </Card>
                                )}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 20, flex: 1}}>
                                {hubData.cameraData.map((item, index) => {
                                        return <Card onClick={() => this.handleCameraClick(item.value, "camera")}
                                                     component={ButtonBase} variant='outlined' key={index} style={{
                                            display: 'flex',
                                            padding: 20,
                                            borderRadius: 5,
                                            flex: 1,
                                            gap: 20,
                                            minHeight: 110,
                                            maxHeight: 120,
                                            color: '#e6e5e8',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#282C34'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                textAlign: 'left'
                                            }}>
                                                <Typography variant='h5'>{item ? item.value : null}</Typography>
                                                <div style={{display: 'flex', flex: 1}}>
                                                    <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                                        <Typography variant='h6'>IN FLOW</Typography>
                                                        <Typography variant='caption'>ADULT
                                                            : {item ? Math.round(item.inAdult) : null}</Typography>
                                                        <Typography variant='caption'>BABY
                                                            : {item ? Math.round(item.inBaby) : null}</Typography>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-end'
                                                    }}>
                                                        <Typography variant='h6'>OUT FLOW</Typography>
                                                        <Typography variant='caption'>ADULT
                                                            : {item ? Math.round(item.outAdult) : null}</Typography>
                                                        <Typography variant='caption'>BABY
                                                            : {item ? Math.round(item.outBaby) : null}</Typography>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>
                                    }
                                )}
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1, width: '80%'}}>
                            {hubData.cameraData.map((item, index) => {
                                    let hostname = hubData && hubData.hubDetails && hubData.hubDetails.hubHostname;
                                    let spl = hostname.split("-");
                                    let portId = spl[spl.length - 1]
                                    return <Card>
                                        <iframe width={480} height={360} title={'stream'}
                                                src={`https://device.yxdian.com/hub-${portId}/picture/1/frame/`}/>
                                    </Card>
                                }
                            )}

                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                "Loading"
            )
        }
    }
}

export default withRouter(HouseData)
