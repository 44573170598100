import React from 'react'
import { Route, Switch,HashRouter } from 'react-router-dom';
import Home from "./Home";
import Login from "./auth/login"
import Signup from './auth/signup'



export default function Routes() {
    return (
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route path='/login'>
                <Login />
            </Route>
            <Route path='/signup'>
                <Signup />
            </Route>
        </Switch>
    )
}