import React from 'react'
import {withRouter} from 'react-router';
import {Link, Breadcrumbs, Typography, Box, Divider, Container, Tabs, Tab} from '@material-ui/core';
import General from './accountComponents/General'
import Security from './accountComponents/Security'

class Account extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'general'
        }
    }

    handleTabsChange = (event, value) => {
        this.setState({currentTab: value});
    };

    tabs = [
        {value: 'general', label: 'General'},
        // { value: 'subscription', label: 'Subscription' },
        // { value: 'notifications', label: 'Notifications' },
        {value: 'security', label: 'Security'}
    ];


    render() {
        return (
            <div style={{height: 'calc(100vh - 105px)'}}>
                <Breadcrumbs aria-label="breadcrumb" separator="›" style={{color: '#e6e5e8'}}>
                    <Link color="inherit" href="/#/ ">
                        <Typography variant='caption'>Dashboard</Typography>
                    </Link>
                    <Typography variant='caption'>Account</Typography>
                </Breadcrumbs>
                <div
                    title="Settings"
                >
                    <Container maxWidth="lg">
                        {/* <Header /> */}
                        <Box mt={3}>
                            <Tabs
                                onChange={this.handleTabsChange}
                                scrollButtons="auto"
                                value={this.state.currentTab}
                                variant="scrollable"
                                textColor="secondary"
                            >
                                {this.tabs.map((tab) => (
                                    <Tab
                                        key={tab.value}
                                        label={tab.label}
                                        value={tab.value}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Divider/>
                        <Box mt={3}>
                            {this.state.currentTab === 'general' && <General/>}
                            {/* {this.state.currentTab === 'subscription' && <Subscription />} */}
                            {/* {this.state.currentTab === 'notifications' && <Notifications />} */}
                            {this.state.currentTab === 'security' && <Security/>}
                        </Box>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(Account)
