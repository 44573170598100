import React from 'react'
import {Grid} from '@material-ui/core';
import ProfileDetails from './General/ProfileDetails'
import GeneralDetails from './General/GeneralDetails'

class General extends React.Component {

    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} xl={3} xs={12}>
                        <ProfileDetails/>
                    </Grid>
                    <Grid item lg={8} md={6} xl={9} xs={12}>
                        <GeneralDetails/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default General
