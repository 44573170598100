import { Button, Card, TextField, Typography, FormControl, Divider } from '@material-ui/core'
import React from 'react'
import logo from '../logo.svg'
import googleLogo from './images/google.svg'
import facebookLogo from './images/facebook.svg'
import './login.css'
import app from '../firebase/firebaseConfig'
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import firebase from 'firebase';



class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email : null,
            password : null,
            alert : false,
            alertMsg : null,
            severity : null,
            showPassword : false,
            Redirect : false,
            showLogin : false
        }
    }

    componentDidMount = async () => {
        document.title = 'Login'
        var that = this
        await app.auth().onAuthStateChanged(function(user) {
            if (user) {
                var uID = user.uid
                app.database().ref(`Users/${uID}`).once("value", async (snapShot) => {
                    if(!snapShot.exists()) {
                        await app.auth().signOut()
                        that.setState({severity:'error', alertMsg:'Need to Sign up', alert:true})
                    } else {
                        that.setState({Redirect : true})
                    }
                }) 
            }else {
                that.setState({showLogin : true})
            }
        })
        
    }

    handleFacebookLogin = () => {
        const facebookRegisterProvider = new firebase.auth.FacebookAuthProvider()
        app.auth().signInWithPopup(facebookRegisterProvider).then((res) => {
            if (res.user) {
                const uID = res.user.uid
                app.database().ref(`Users/${uID}`).once("value", (snapShot) => {
                    if (!snapShot.exists()) {
                        app.database().ref(`Users/${uID}`).set(
                            {
                            'userInfo' : {
                                "fName" : (res.user.displayName).split(' ')[0],
                                "lName" : (res.user.displayName).split(' ')[1],
                                "access" : 'User'
                            }
                        })
                    }
                })
            }
        }).then(() => {
            this.setState({Redirect : true})
        })
    }

    handleGoogleLogin = () => {
        const googleRegisterProvider = new firebase.auth.GoogleAuthProvider()
        app.auth().signInWithPopup(googleRegisterProvider).then((res) => {
            console.log(111111111111111,res)
            if (res.user) {
                const uID = res.user.uid;
                app.database().ref(`Users/${uID}`).once("value", (snapShot) => {
                    if (!snapShot.exists()) {
                        app.database().ref(`Users/${uID}`).set(
                            {
                            'userInfo' :""
                        })
                    }
                })
            }
        }).then(() => {
            // setTimeout(function(){
            //     self.setState({Redirect : true})
            // },3000)
        })
    }

    handleLogin = async () => {
        var that = this;
        app.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
              that.setState({severity:'error', alertMsg:'Wrong Password', alert:true})
            } else {
                that.setState({severity:'error', alertMsg: errorMessage, alert:true})
            }
        })
    }

    handleClose = () => {
        this.setState({alert:false})
    }

    handleClickShowPassword = () => {
        this.setState({showPassword : !this.state.showPassword})
    }

    render() {
        return(
            <div style={{minHeight:'100vh', display:'flex', backgroundColor:'#1c2025', justifyContent:'center', alignItems:'center'}}>
            {this.state.Redirect ? <Redirect to='/' /> : 
            <div style={{display:'flex',width:"100%",height:'100%', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <Card variant='elevation' style={{display:'flex', borderRadius:60, width:70, height:70, justifyContent:'center'}}>
                    <object aria-label='logo' style={{padding: 10}} data={logo} type="image/svg+xml"/>
                </Card>
                <form  style={{marginTop:20, width:'100%', display:'flex', justifyContent:'center'}}>
                {this.state.showLogin ? <Card style={{display:'flex', backgroundColor:'#282C34',}}>
                        <div style={{display:'flex', flexDirection:'column', padding:'32px 32px 24px', gap:12}}>
                            <Typography style={{fontSize:'1.6667rem', fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif'}}>Sign in</Typography>
                            <Typography>Sign in to Swift Hub</Typography>
                            <div style={{display:'flex', columnGap:20, flex:1, minWidth:'100%', maxHeight:43}}>
                                <Button elevation={2} variant='outlined' style={{backgroundColor:'white'}} onClick={this.handleGoogleLogin}>
                                    <object aria-label='google-logo' style={{width:'18px', marginRight:10}} data={googleLogo} type="image/svg+xml" />
                                    <Typography variant='overline' style={{color:'rgb(28, 32, 37)'}}>SIGN IN WITH GOOGLE</Typography>
                                </Button>
                                <Button variant='outlined' style={{backgroundColor:'white'}} onClick={this.handleFacebookLogin}>
                                    <object aria-label='facebook-logo' style={{width:'18px', marginRight:10, fill:'blue'}} data={facebookLogo} type="image/svg+xml" />
                                    <Typography variant='overline' style={{color:'rgb(28, 32, 37)'}}>SIGN IN WITH FACEBOOK</Typography>
                                </Button>
                            </div>
                            <div style={{display:'flex', alignItems:'center', columnGap:10, color:'#e6e5e8'}}>
                                <Divider
                                    style={{flexGrow:1, backgroundColor:'rgba(255, 255, 255, 0.12)'}}
                                    orientation="horizontal"
                                />
                                <Typography 
                                    
                                    variant="body1"
                                >
                                    OR
                                </Typography>
                                <Divider
                                    style={{flexGrow:1, backgroundColor:'rgba(255, 255, 255, 0.12)'}}
                                    orientation="horizontal"
                                />
                            </div>
                            <TextField variant='outlined' autoComplete={"username"}  label='Email Address' style={{marginTop:16, marginBottom:8}} onChange={(event) => this.setState({email : event.target.value})} />
                            <FormControl>
                            <TextField autoComplete={"current-password"} type={this.state.showPassword ? 'text' : 'password'} style={{marginTop:16, marginBottom:8}} variant='outlined' label='Password' onChange={(event) => this.setState({password : event.target.value})} 
                                InputProps = {{endAdornment:
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                    >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }}
                                
                            />
                            </FormControl>

                            <Button variant='text' color='primary' style={{padding:10, marginTop:16, marginBottom:8, backgroundColor:'#5850EC', color:'white'}} onClick={this.handleLogin}>Log in</Button>
                            <Divider style={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} />
                            <Typography variant='caption'>Create new account <Link to='/signup'>Register</Link></Typography>
                        </div>
                    </Card>
                    : null }
                    
                    <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                            {this.state.alertMsg}
                        </Alert>
                    </Snackbar>
                </form>
            </div>
            }
            </div>
        )
    }
}

export default Login
