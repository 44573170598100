import {
    Divider,
    Typography,
    AppBar,
    Toolbar,
    Avatar,
    Box,
    ButtonBase,
    Menu,
    MenuItem,
    Hidden,
    IconButton,
    SvgIcon,
    Drawer,
    withStyles,
    Card
} from '@material-ui/core';
import {List, ListItem, ListItemIcon} from '@material-ui/core';
import React from 'react';
import {withRouter, Redirect,} from 'react-router';
import {Link, NavLink, HashRouter, Route} from "react-router-dom";
import app from './firebase/firebaseConfig';
import logo from './logo.svg'
import {Menu as MenuIcon} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Account from './dashboardComponents/Account';
import Dashboard from './dashboardComponents/dashboard';
import MyHouses from './dashboardComponents/myHouses';
import HouseData from './dashboardComponents/deviceComponents/houseData';
import Mobile from './mobileComponents/Index'

import {
    User as UserIcon,
    PieChart as PieChartIcon,
    Users as UsersIcon
} from 'react-feather';

const useStyles = (theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        backgroundColor: "#282C34",
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        backgroundColor: "#1c2025",
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
})

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: false,
            uID: null,
            Redirect: false,
            userName: null,
            userPhoto: null,
            menuOpen: false,
            anchorEl: null,
            openNav: false,
            sensorData: null
        }
    }


    componentDidMount() {
        this.getDeviceId()
        document.title = 'Swift Hub Dashboard'
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({user: true, uID: user.uid, userName: user.displayName, userPhoto: user.photoURL})
                this.setUserDeviceList(user)
                app.database().ref('sensorData').on('value', (snapShot) => {
                    this.setState({sensorData: snapShot.val()})
                })
            } else {
                this.setState({Redirect: true})
            }
        })
    }

    setUserDeviceList(user) {
        let ids = localStorage.getItem('DEVICE_ID')
        if (!ids) return
        let deviceList = ids.split(',') || []
        app.database().ref('/HubData').on('value', (snapShot) => {
            let hubAccess = {}
            let hubListMap = snapShot.val()
            if (!deviceList || deviceList.length === 0) {
                return
            }
            deviceList && deviceList.forEach((key, index) => {
                hubAccess[key] = hubListMap[key] ? hubListMap[key] : {hubName: ''}
            })
            app.database().ref(`/Users/${user.uid}`).on("value", (snapShot) => {
                let userInfo = snapShot.val()
                if (userInfo && userInfo.hubAccess) {
                    this.updateUserHubLib(user, hubAccess, userInfo.hubAccess)
                } else {
                    this.createUserHubLib(user, hubAccess)
                }
            })
        })
    }

    createUserHubLib(user, hubAccess) {
        app.database().ref(`/Users/${user.uid}`).update({hubAccess}, (snapShot) => {
            console.log('-----create hub success-------', snapShot)
            localStorage.removeItem("DEVICE_ID")
        })
    }

    updateUserHubLib(user, hubAccess, oldHubAccess) {
        app.database().ref(`/Users/${user.uid}/hubAccess`).update(hubAccess, (snapShot) => {
            console.log('-----update hub success-------', snapShot)
            localStorage.removeItem("DEVICE_ID")

        })
    }

    getDeviceId() {
        let ids = localStorage.getItem('DEVICE_ID')
        let search = this.props.history.location.hash
        if (search && search.indexOf("#/?deviceId=") > -1) {
            let deviceId = ids ? ids + ',' + search.replace("#/?deviceId=", '') : search.replace("#/?deviceId=", '')
            localStorage.setItem("DEVICE_ID", deviceId)
        }
    }

    handleMenuClose = () => {
        this.setState({anchorEl: null, menuOpen: false})
    }

    handleLogout = async () => {
        await app.auth().signOut().then(() => {
            this.setState({user: false, Redirect: true})
        })
    }

    render() {
        let pathname = this.props.history.location.hash
        if (this.state.user) {
            const {classes} = this.props;
            const content = (
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    <PerfectScrollbar options={{suppressScrollX: true}} style={{height: '100%'}}>
                        <Hidden lgUp>
                            <Box
                                p={2}
                                display="flex"
                                justifyContent="center"
                            >
                                <Link to="/">
                                    <logo/>
                                </Link>
                            </Box>
                        </Hidden>
                        <Box p={2}>
                            <Box
                                display="flex"
                                justifyContent="center"
                            >
                                <Avatar
                                    alt="User"
                                    className={classes.avatar}
                                    src={this.state.userPhoto}
                                />
                            </Box>
                            <Box
                                mt={2}
                                textAlign="center"
                                color='#e6e5e8'
                            >
                                {this.state.userName}

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >

                                </Typography>
                            </Box>
                        </Box>
                        <Divider style={{backgroundColor: 'rgba(255, 255, 255, 0.12)'}}/>
                        <Box p={2}>
                            <List>
                                <NavLink to='/' exact style={{textDecoration: 'none', color: '#adb0bb',}} activeStyle={{
                                    fontWeight: 500,
                                    color: "#5850EC"
                                }}>
                                    <ListItem button key={1}>
                                        <ListItemIcon style={{color: 'inherit', minWidth: 25}}><PieChartIcon
                                            size='1rem'/></ListItemIcon>
                                        <Typography style={{
                                            fontWeight: 500,
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                            letterSpacing: 0,
                                            lineHeight: 1.75,
                                            fontSize: '.875rem'
                                        }}>Dashboard</Typography>
                                    </ListItem>
                                </NavLink>
                                <NavLink style={{textDecoration: 'none', color: '#adb0bb',}} activeStyle={{
                                    fontWeight: 500,
                                    color: "#5850EC"
                                }} to='/myhouses'>
                                    <ListItem button key={2}>
                                        <ListItemIcon style={{color: 'inherit', minWidth: 25}}><UsersIcon size='1rem'/></ListItemIcon>
                                        <Typography style={{
                                            fontWeight: 500,
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                            letterSpacing: 0,
                                            lineHeight: 1.75,
                                            fontSize: '.875rem'
                                        }}>My Houses</Typography>
                                    </ListItem>
                                </NavLink>

                            </List>
                        </Box>

                    </PerfectScrollbar>
                    <Card variant='outlined' style={{backgroundColor: '#282C34', zIndex: 1200}}>
                        <Box p={1}>
                            <NavLink style={{textDecoration: 'none', color: '#adb0bb'}} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }} to='/account'>
                                <ListItem button key={2}>
                                    <ListItemIcon style={{color: 'inherit', minWidth: 25}}><UserIcon
                                        size='1rem'/></ListItemIcon>
                                    <Typography style={{
                                        fontWeight: 500,
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        letterSpacing: 0,
                                        lineHeight: 1.75,
                                        fontSize: '.875rem'
                                    }}>Account</Typography>
                                </ListItem>
                            </NavLink>
                        </Box>
                    </Card>
                </Box>
            )
            return (
                <div style={{display: 'flex', height: '100%', width: '100%', flexDirection: 'column'}}>
                    <HashRouter>
                        <Route path='/mobile'>
                            <Mobile/>
                        </Route>
                        <AppBar variant='elevation' position='sticky' style={{
                            display: pathname.indexOf('mobile') > -1 ? 'none' : "flex",
                            flexDirection: "row",
                            flex: 1,
                            backgroundColor: '#282C34',
                            maxHeight: 64,
                            zIndex: 1300
                        }}>
                            <Hidden lgUp>
                                <IconButton
                                    color="inherit"
                                    onClick={() => this.setState({openNav: true})}
                                >
                                    <SvgIcon fontSize="small">
                                        <MenuIcon/>
                                    </SvgIcon>
                                </IconButton>
                            </Hidden>
                            <Hidden mdDown>
                                <div style={{margin: "10px 20px 5px 20px"}}>
                                    <Link to='/'><img alt='logo' src={logo} width={40} /></Link>
                                </div>
                            </Hidden>
                            <div style={{display: 'flex', flex: 1}}/>
                            <Toolbar>
                                <div style={{
                                    margin: "6px 20px 7px 20px",
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5
                                }}>
                                    <Box display='flex' flexDirection='row-reverse' style={{gap: 8}}
                                         component={ButtonBase} onClick={(e) => {
                                        this.setState({anchorEl: e.currentTarget, menuOpen: true})
                                    }}>
                                        <Typography variant='caption' color='inherit'>{this.state.userName}</Typography>
                                        {this.state.userPhoto ?
                                            <Avatar style={{height: 32, width: 32}} alt={this.state.userName}
                                                    src={this.state.userPhoto}/> :
                                            <Avatar style={{height: 32, width: 32}}
                                                    alt={this.state.userName}>{this.state.userName ? (this.state.userName).charAt(0) : null}</Avatar>}
                                    </Box>
                                    <a href="/#/mobile" style={{marginRight: 10}}><Typography variant='caption'
                                                                                              color='inherit'>help</Typography></a>
                                    <Menu
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        keepMounted
                                        getContentAnchorEl={null}
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.menuOpen}
                                    >
                                        <MenuItem onClick={this.handleLogout}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </Toolbar>
                        </AppBar>

                        <div style={{display: pathname.indexOf('mobile') > -1 ? 'none' : 'flex',}}>
                            <div>
                                <Hidden lgUp>
                                    <Drawer
                                        anchor="left"
                                        classes={{paper: classes.mobileDrawer}}
                                        onClose={() => this.setState({openNav: false})}
                                        open={this.state.openNav}
                                        variant="temporary"
                                    >
                                        {content}
                                    </Drawer>
                                </Hidden>
                                <Hidden mdDown>
                                    <Drawer
                                        anchor="left"
                                        classes={{paper: classes.desktopDrawer}}
                                        open
                                        variant="persistent"
                                    >
                                        {content}
                                    </Drawer>
                                </Hidden>
                            </div>
                            <div className={classes.wrapper}>
                                <div className={classes.contentContainer}>
                                    <div className={classes.content}>
                                        <Box style={{margin: 20}}>
                                            <Route exact path='/' component={Dashboard}/>
                                            <Route exact path='/myhouses' component={MyHouses}/>
                                            <Route exact path='/account' component={Account}/>
                                            <Route exact path='/myhouses/data' component={HouseData}/>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashRouter>
                </div>
            )
        } else {
            return (
                <div>
                    {this.state.Redirect ? <Redirect to='/login'/> : null}
                </div>
            )

        }

    }

}


export default withStyles(useStyles)(withRouter(Home))
