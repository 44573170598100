import React from 'react'
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography} from '@material-ui/core';

import app from '../../firebase/firebaseConfig'


class Security extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: null,
            cpassword: null,
            provider: null
        }
    }

    componentDidMount = () => {
        const user = app.auth().currentUser
        if (user != null) {
            const that = this
            user.providerData.forEach(function (profile) {
                if (profile.providerId === 'google.com') {
                    that.setState({provider: 'google'})
                } else if (profile.providerId === 'password') {
                    that.setState({provider: 'password'})
                }
            })
        }
    }

    handleSubmit = () => {

    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader title="Change Password"/>
                    <Divider/>
                    {this.state.provider === 'password' ?
                        <div>
                            <CardContent>
                                <Grid container spacing={3}> <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        fullWidth label="Password" name="password"
                                        onChange={(e) =>
                                            this.setState({password: e.target.value})} type="password"
                                        value={this.state.password} variant="outlined"/> </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <TextField fullWidth label="Password Confirmation" name="passwordConfirm"
                                                   onChange={(e) =>
                                                       this.setState({cpassword: e.target.value})}
                                                   type="password" value={this.state.cpassword} variant="outlined"/>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <Divider/>

                            <Box p={2} display="flex" justifyContent="flex-end">
                                <Button color="secondary"
                                        type="submit"
                                        variant="contained"
                                        onClick={this.handleSubmit}> Change
                                    Password
                                </Button>
                            </Box>
                        </div>
                        :
                        <Box p={2} display="flex"> <Typography> No need for password, if user logged in
                            with {this.state.provider} </Typography>
                        </Box>}
                </Card>

            </div>
        )
    }
}

export default Security
