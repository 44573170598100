import React from 'react'
import {Avatar, Box, Button, Card, CardActions, CardContent, Typography,} from '@material-ui/core';
import app from '../../../firebase/firebaseConfig'

class ProfileDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null
        }
    }

    componentDidMount = () => {
        const user = app.auth().currentUser
        const userDetails = {}
        userDetails.avatar = user.photoURL
        userDetails.name = user.displayName
        this.setState({user: userDetails})
    }

    removePic = () => {
        var that = this
        app.auth().currentUser.updateProfile({
            photoURL: ''
        }).then(function () {
            let data = that.state.user
            data.photoURL = ''
            that.setState({user: data})
        })
    }

    render() {
        if (this.state.user) {
            return (
                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            textAlign="center"
                        >
                            <Avatar
                                src={this.state.user.avatar}
                                style={{width: 100, height: 100}}
                            />
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h3"
                                style={{marginTop: 10}}
                            >
                                {this.state.user.name}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button
                            fullWidth
                            variant="text"
                            onClick={this.removePic}
                        >
                            Remove picture
                        </Button>
                    </CardActions>
                </Card>
            )
        } else {
            return null
        }
    }
}

export default ProfileDetails
