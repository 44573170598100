import React from 'react'
import Typography from '@material-ui/core/Typography';
import {Breadcrumbs, ButtonBase, Card} from '@material-ui/core';
import app from '../firebase/firebaseConfig'
import MyComponent from './subComponent/map'
import {withRouter,} from 'react-router';
import 'fontsource-roboto';
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyBczwvKeESYf4HsyXOZy8a80DJWGVm4Eig");

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uID: null,
            houseAccess: null,
            loadMap: false,
            markers: {},
        }
    }

    componentDidMount = () => {
        const userID = app.auth().currentUser.uid
        let data;
        console.log(userID)
        app.database().ref(`Users/${userID}/hubAccess`).on('value', (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                data = dataSnapshot.val();
                const houseKeys = Object.keys(data)
                let newMarker = {}
                let alarming = 0
                for (let i = 0; i < houseKeys.length; i++) {
                    const address = data[houseKeys[i]].hubDetails.location;
                    const hubName = data[houseKeys[i]].hubDetails.hubHostname;
                    const path = houseKeys[i]
                    newMarker[path] = ({
                        markerOffset: 15,
                        name: hubName,
                        coordinates: address ? [address.lat, address.lng] : [31.230378, 121.473658]
                    })
                    app.database().ref(`HubData/${houseKeys[0]}/hubDetails/onlineStatus`).on('value', (dataSnapshot) => {
                        if (dataSnapshot.exists()) {
                            if (dataSnapshot.val() === "Offline") {
                                alarming = alarming + 1
                            }
                        }
                    })

                }
                console.log('newMarker', newMarker)
                this.setState({alarm: alarming})
                this.setState({markers: newMarker})
                this.setState({houseAccess: data})
                this.setState({loadMap: true})

            }
        })

        this.setState({uID: userID})

    }

    onUnmount = () => {
        this.setState({loadMap: false})
    }

    render() {
        return (
            <div style={{height: 'calc(100vh - 105px)', overflow: 'unset'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color: '#e6e5e8'}}>
                    <Typography variant='caption' color='inherit'>Dashboard</Typography>
                </Breadcrumbs>
                {this.state.loadMap ? <div>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex'}}>
                                    <Card style={{display: 'flex', height: '60vh', width: '100%'}}>
                                        <MyComponent data={this.state.markers}/>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1, marginTop: 10, minHeight: 100, gap: 15}}>
                            <Card component={ButtonBase} onClick={() => this.props.history.push('/myhouses')} style={{
                                borderRadius: 5,
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                padding: 10,
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#282C34'
                            }}>
                                <Typography variant='h2' style={{
                                    fontWeight: 800,
                                    color: '#e6e5e8',
                                    textDecorationLine: 'underline'
                                }}>{this.state.houseAccess ? Object.keys(this.state.houseAccess).length : 0}</Typography>
                                <Typography variant='h5' style={{fontWeight: 800, color: '#e6e5e8'}}>My Houses</Typography>
                            </Card>
                            <Card style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#282C34'
                            }}>
                                <Typography variant='h2' style={{
                                    fontWeight: 800,
                                    color: '#f44336',
                                    textDecorationLine: 'underline'
                                }}>{this.state.alarm}</Typography>
                                <Typography variant='h5' style={{fontWeight: 800, color: '#f44336'}}>Alarming</Typography>
                            </Card>
                        </div>
                    </div>
                    : <div>Loading</div>}
            </div>
        )
    }
}

export default withRouter(Dashboard)
