import React from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  Typography,
  useTheme
} from '@material-ui/core';
import moment from 'moment';

const LineChart = (props) => {
  const theme = useTheme();
  let key = props.data.key ? props.data.key : "humidity"
  let list = []
  props.data && props.data.data.forEach((item,index)=>{
    let val = item[key].toFixed(2)
    list.push(val)
  })
  let timelist = []
  props.data && props.data.time.forEach((val,index)=>{
    val = moment(val,"YYYYMMDDHHmm").format("HH:mm")
    timelist.push(val)
  })
  const chart = {
    options: {
      noData: {
        text: "There's no data",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0
        },
      chart: {
        // background: theme.palette.background.paper,
        background : 'rgb(40, 44, 52)',
        stacked: false,
        toolbar: {
          show: true
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
      },
      colors: ['#8a85ff', '#ff5c7c', '#FE9305'],
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: "#e6e5e8",
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: "#e6e5e8"
        }
      },
      markers: {
        size: 4,
        strokeColors: ['#8a85ff', '#27c6db'],
        strokeWidth: 0,
        shape: 'circle',
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      stroke: {
        width: 3,
        curve: 'smooth',
        // lineCap: 'butt',
        dashArray: [0, 3]
      },
      theme: {
        mode: theme.palette.type
      },
      tooltip: {
        theme: theme.palette.type
      },
      xaxis: {
        axisBorder: {
          color: "#e6e5e8"
        },
        axisTicks: {
          show: true,
          color: "#e6e5e8"
        },
        categories: timelist.splice(0,100),
        // tickAmount: 10,
        labels: {
          style: {
            colors: "#e6e5e8"
          }
        }
      },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: "#e6e5e8"
          },
          axisTicks: {
            show: true,
            color: "#e6e5e8"
          },
          labels: {
            style: {
              colors: "#e6e5e8"
            }
          }
        }
      ]
    },
    series: [
      {
        name: props.data.label,
        data: list.splice(0,100)
      },
    ],
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 10
      }
    },
    
  };

  return (
    <Card style={{backgroundColor:'rgb(40, 44, 52)', width:'100%'}}>
      <CardContent>
        <Typography
          variant="h4"
          color="primary"
        >
          {props.data.label}
        </Typography>
        <Chart
          type="line"
          height="300"
          {...chart}
        />
      </CardContent>
    </Card>
  );
};

export default LineChart;
