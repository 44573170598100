import React from 'react'
import {withRouter} from 'react-router';
import {HashRouter, Route, Switch} from "react-router-dom";
import {AppBar, Tabs, Tab} from '@material-ui/core';
import Header from "./Header";
import './index.css'
import Guidance from './Guidance';
import Devices from './Devices';
import Personal from './Personal';
import app from '../firebase/firebaseConfig';
import intl from 'react-intl-universal';


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 0,
            userName: ""
        }
    }

    componentDidMount() {
        let pathname = this.props.location.pathname
        if (pathname === '/mobile') {
            this.props.history.push('/mobile/guidance')
        }
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({user: true, uID: user.uid, userName: user.displayName, userPhoto: user.photoURL})
                app.database().ref('sensorData').on('value', (snapShot) => {
                    this.setState({sensorData: snapShot.val()})
                })
            } else {
                this.setState({Redirect: true})
            }
        })
    }

    handleTabsChange = (event, value) => {
        let list = ['/mobile/guidance', '/mobile/devices', '/mobile/personal']
        let path = list[value]
        this.props.history.push(path)
        this.setState({currentTab: value});
    };

    tabs = [
        {value: 'general', label: 'General'},
        // { value: 'subscription', label: 'Subscription' },
        // { value: 'notifications', label: 'Notifications' },
        {value: 'security', label: 'Security'}
    ];
    handleLogout = async () => {
        await app.auth().signOut().then(() => {
            window.location.href = '/login'
        })
    }

    render() {
        let {currentTab, userName} = this.state
        return (
            <HashRouter>
                <div className="swift-mobile-layout">
                    <div className="header">
                        <Header handleLogout={() => this.handleLogout()} userName={userName} position="fixed">
                        </Header>
                    </div>
                    <div className="content">
                        <Switch>
                            <Route path='/mobile/guidance'>
                                <Guidance/>
                            </Route>
                            <Route path='/mobile/devices'>
                                <Devices/>
                            </Route>
                            <Route path='/mobile/personal'>
                                <Personal/>
                            </Route>
                        </Switch>
                    </div>
                    <div className="footer">
                        <AppBar position="static" color="inherit">
                            <Tabs
                                value={currentTab}
                                onChange={(event, newValue) => this.handleTabsChange(event, newValue)}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={intl.get("GUID")}  {...a11yProps("Guidance")} />
                                <Tab label={intl.get("DEVICE")} {...a11yProps("Devices")} />
                                <Tab label={intl.get("PER")} {...a11yProps("Personal")} />
                            </Tabs>
                        </AppBar>
                    </div>
                </div>
            </HashRouter>
        )
    }
}

export default withRouter(Index)
