import React from 'react'
import { withRouter, Redirect } from 'react-router';
import { Link, Breadcrumbs, Typography, Card, Box, Divider, Container, Tabs, Tab } from '@material-ui/core';
import app from '../firebase/firebaseConfig';

class Personal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'general'
        }
    }
    componentDidMount() {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user: true, uID: user.uid, userName: user.displayName, userPhoto: user.photoURL })
                app.database().ref('sensorData').on('value', (snapShot) => {
                    this.setState({ sensorData: snapShot.val() })
                })
            } else {
                this.setState({ Redirect: true })
            }
        })
    }
    handleTabsChange = (event, value) => {
        this.setState({ currentTab: value });
    };

    tabs = [
        { value: 'general', label: 'General' },
        { value: 'security', label: 'Security' }
    ];


    render() {
        return (
            <div>
                personal center
            </div>
        )
    }
}

export default withRouter(Personal)