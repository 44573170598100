import {Button, Card, TextField, Typography, FormControl, Divider} from '@material-ui/core'
import React from 'react'
import logo from '../logo.svg'
import googleLogo from './images/google.svg'
import facebookLogo from './images/facebook.svg'
import './login.css'
import app from '../firebase/firebaseConfig'
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Redirect} from 'react-router'
import {Link} from "react-router-dom";
import firebase from 'firebase';

class Signup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: null,
            password: null,
            cPassword: null,
            fName: null,
            lName: null,
            alert: false,
            alertMsg: null,
            severity: null,
            showPassword: false,
            showConfirmPassword: false,
            Redirect: false,
            showRegister: false
        }
    }

    componentDidMount = async () => {
        document.title = 'Register'
        const that = this
        await app.auth().onAuthStateChanged(function (user) {
            if (user) {

                that.setState({Redirect: true})

            } else {
                that.setState({showRegister: true})
            }
        })
    }

    handleFacebookRegister = () => {

    }

    handleGoogleRegister = () => {
        const googleRegisterProvider = new firebase.auth.GoogleAuthProvider()
        app.auth().signInWithPopup(googleRegisterProvider).then((res) => {
            if (res.user) {
                const uID = res.user.uid
                app.database().ref(`Users/${uID}`).set(
                    {
                        'userInfo': {
                            "fName": (res.user.displayName).split(' ')[0],
                            "lName": (res.user.displayName).split(' ')[1],
                            "access": 'User'
                        }
                    }).then(() => {
                    this.setState({Redirect: true})
                })
            }
        })
    }

    handleRegister = () => {
        app.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
            if (user) {
                const dName = this.state.fName + ' ' + this.state.lName;
                const uID = user.user.uid;
                app.database().ref(`Users/${uID}`).set({
                    'userInfo': {
                        "fName": this.state.fName,
                        "lName": this.state.lName,
                        "access": 'User'
                    }
                }).then(() => {
                    const cUser = app.auth().currentUser;
                    const that = this;
                    cUser.updateProfile({
                        displayName: dName,
                    }).then(function () {
                        that.setState({Redirect: true})
                        // Update successful.
                    }).catch(function (error) {
                        // An error happened.
                    });
                })
            }
        })

    }

    handleClose = () => {
        this.setState({alert: false})
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    }

    handleClickShowConfirmPassword = () => {
        this.setState({showConfirmPassword: !this.state.showConfirmPassword})
    }

    render() {
        return (
            <div style={{minHeight: '100vh', paddingTop: 20, backgroundColor: '#1c2025', paddingBottom: 20}}>
                {this.state.Redirect ? <Redirect to='/'/> :
                    <div style={{
                        display: 'flex',
                        width: "100%",
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Card variant='elevation' style={{
                            display: 'flex',
                            borderRadius: 60,
                            width: 70,
                            height: 70,
                            justifyContent: 'center'
                        }}>
                            <object aria-label='logo' style={{width: '60px'}} data={logo} type="image/svg+xml"/>
                        </Card>
                        <form style={{marginTop: 20, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            {this.state.showRegister ? <Card style={{display: 'flex', backgroundColor: '#282C34'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '32px 32px 24px',
                                        gap: 12
                                    }}>
                                        <Typography style={{
                                            fontSize: '1.6667rem',
                                            fontWeight: 500,
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                                        }}>Register</Typography>
                                        <Typography>Sign up to Swift Hub</Typography>
                                        <div style={{display: 'flex', columnGap: 20, flex: 1, minWidth: '100%'}}>
                                            <Button elevation={2} variant='outlined' style={{backgroundColor: 'white'}}
                                                    onClick={this.handleGoogleRegister}>
                                                <object aria-label='google-logo' style={{width: '18px', marginRight: 10}}
                                                        data={googleLogo} type="image/svg+xml"/>
                                                <Typography variant='overline' style={{color: 'rgb(28, 32, 37)'}}>REGISTER
                                                    WITH GOOGLE</Typography>
                                            </Button>
                                            <Button variant='outlined' style={{backgroundColor: 'white'}}
                                                    onClick={this.handleFacebookRegister}>
                                                <object aria-label='facebook-logo'
                                                        style={{width: '18px', marginRight: 10, fill: 'blue'}}
                                                        data={facebookLogo} type="image/svg+xml"/>
                                                <Typography variant='overline' style={{color: 'rgb(28, 32, 37)'}}>REGISTER
                                                    WITH FACEBOOK</Typography>
                                            </Button>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', columnGap: 10}}>
                                            <Divider
                                                style={{flexGrow: 1, backgroundColor: 'rgba(255, 255, 255, 0.12)'}}
                                                orientation="horizontal"
                                            />
                                            <Typography
                                                variant="body1"
                                            >
                                                OR
                                            </Typography>
                                            <Divider
                                                style={{flexGrow: 1, backgroundColor: 'rgba(255, 255, 255, 0.12)'}}
                                                orientation="horizontal"
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            minWidth: '100%',
                                            justifyContent: 'space-between'
                                        }}>
                                            <TextField variant='outlined' label='First Name'
                                                       style={{marginTop: 16, marginBottom: 8}}
                                                       onChange={(event) => this.setState({fName: event.target.value})}/>
                                            <TextField variant='outlined' label='Last Name'
                                                       style={{marginTop: 16, marginBottom: 8}}
                                                       onChange={(event) => this.setState({lName: event.target.value})}/>
                                        </div>
                                        <TextField variant='outlined' label='Email Address'
                                                   style={{marginTop: 16, marginBottom: 8}}
                                                   onChange={(event) => this.setState({email: event.target.value})}/>
                                        <FormControl>
                                            <TextField type={this.state.showPassword ? 'text' : 'password'}
                                                       style={{marginTop: 16, marginBottom: 8}} variant='outlined'
                                                       label='Password'
                                                       onChange={(event) => this.setState({password: event.target.value})}
                                                       InputProps={{
                                                           endAdornment:
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       aria-label="toggle password visibility"
                                                                       onClick={this.handleClickShowPassword}
                                                                   >
                                                                       {this.state.showPassword ? <Visibility/> :
                                                                           <VisibilityOff/>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                       }}

                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                       style={{marginTop: 16, marginBottom: 8}} variant='outlined'
                                                       label='Confirm Password'
                                                       onChange={(event) => this.setState({cPassword: event.target.value})}
                                                       InputProps={{
                                                           endAdornment:
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       aria-label="toggle password visibility"
                                                                       onClick={this.handleClickShowConfirmPassword}
                                                                   >
                                                                       {this.state.showConfirmPassword ? <Visibility/> :
                                                                           <VisibilityOff/>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                       }}

                                            />
                                        </FormControl>

                                        <Button variant='text' color='primary' style={{
                                            padding: 10,
                                            marginTop: 16,
                                            marginBottom: 8,
                                            backgroundColor: '#5850EC',
                                            color: 'white'
                                        }} onClick={this.handleRegister}>REGISTER</Button>
                                        <Divider style={{backgroundColor: 'rgba(255, 255, 255, 0.12)'}}/>
                                        <Typography variant='caption'>Have an account <Link to='/login'>Log
                                            in</Link></Typography>
                                    </div>
                                </Card>
                                : null}

                            <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleClose}>
                                <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                                    {this.state.alertMsg}
                                </Alert>
                            </Snackbar>
                        </form>
                    </div>
                }
            </div>
        )
    }
}

export default Signup
