// import logo from './logo.svg';
import './App.css';
import rtl from 'jss-rtl';
import { withRouter } from 'react-router'
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { createTheme } from './theme';
import Routes from './routes'
import { create } from 'jss';
import useSettings from './hooks/useSettings';
import React, { useEffect } from 'react'
import intl from 'react-intl-universal';

// locale data
const locales = {
  "en-US": require('./asset/locales/en-US.json'),
  "zh-CN": require('./asset/locales/zh-CN.json'),
};
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { settings } = useSettings();
  useEffect(() => {
    let locale = localStorage.getItem('lang_type') || "en-US"
    intl.init({
      currentLocale: locale, // TODO: determine locale here
      locales,
    })
  }, [])
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });


  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Routes />
      </StylesProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);
