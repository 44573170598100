import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import pcImg from '../img/pc.png';
import ampImg from '../img/amp.png';
import cameraImg from '../img/camera.png';
import airImg from '../img/air.png';
import intl from 'react-intl-universal';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <>
    <h1 style={{ textAlign: "center" }}>{intl.get("DEVICE_TITLE")}</h1>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff', marginBottom: 5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅰ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("DEVICE_INTRO1")}</strong></span>}
        subheader="September 14, 2016"
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO_TITLE")}</h3>
      <img src={pcImg} style={{width: "90vw", marginLeft: "5vw"}}/>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("INSTALL_PROCEDURE")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("INSTALL_COTENT1")}.<br />
          （2）{intl.get("INSTALL_COTENT2")}
        </Typography>
      </CardContent>
    </Card>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff', marginBottom: 5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅱ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("DEVICE_INTRO2")}</strong></span>}
        subheader="September 14, 2016"
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO")}</h3>
      <img src={cameraImg} style={{ width: "90vw", marginLeft: "5vw" }}></img>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("INSTALL_PROCEDURE")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("INSTALL_COTENT1")}.<br />
          （2）{intl.get("INSTALL_COTENT3")}
        </Typography>
      </CardContent>
    </Card>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff', marginBottom: 5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅲ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("DEVICE_INTRO3")}</strong></span>}
        subheader=""
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO")}</h3>
      <img src={ampImg} style={{ width: "90vw", marginLeft: "5vw" }}></img>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("INSTALL_PROCEDURE")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("INSTALL_COTENT4")}<br />
          （2）{intl.get("INSTALL_COTENT6")}<br />
          （3）{intl.get("INSTALL_COTENT7")}<br />
          （4）{intl.get("INSTALL_COTENT8")}<br />
          （5）Choose "Repeater Mode"，Click ”NEXT”:
         <p style={{ paddingLeft: 20 }}>
            If you need to turn the light off , <br />
            click the blue button in the upper left hand corner . <br />
            Click "System" and click “Light control switch” turn it to off.
            </p>
          （6）Click ”Scan routing network”.Choose the Wi-Fi which need to enhance.
          <br></br>
          （7）Click “SSID Clone”,and enter the password.
          <br></br>
          （8）Click ”Save” to complete setup.
        </Typography>
      </CardContent>
    </Card>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff' }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅳ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>AIR QUALITY DETECTOR CONFIGURATION</strong></span>}
        subheader="September 14, 2016"
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO")}</h3>
      <img src={airImg} style={{ width: "90vw", marginLeft: "5vw" }}></img>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("INSTALL_PROCEDURE")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("INSTALL_COTENT4")}<br />
          （2）{intl.get("INSTALL_COTENT5")}
        </Typography>
      </CardContent>
    </Card>
    <div style={{textAlign:"center",marginBottom: 70,marginTop:10}}>
      <Button onClick={()=>props.onChangeStep()} style={{width:"200px"}} variant="contained" size="large" color="primary">{intl.get("NEXT")}</Button>
    </div>
  </>;
}
