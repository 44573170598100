import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import dashboardImg from '../img/web01.jpeg';
import houseImg from '../img/web02.jpeg';
import intl from 'react-intl-universal';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return <>
    <h1 style={{textAlign:"center"}}>System Use Tutorials</h1>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff',marginBottom:5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅰ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("DASHBOARD")}</strong></span>}
        subheader=""
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO_TITLE1")}</h3>
      <img src={dashboardImg} style={{width: "90vw", marginLeft: "5vw"}}/>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2.{intl.get("SYS_USW_TUT_TITLE2")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("SYS_USW_TUT_CONTENT1")}<br />
          （2）{intl.get("SYS_USW_TUT_CONTENT2")}
        </Typography>
      </CardContent>
     </Card>
     <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff',marginBottom:5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅱ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("SYS_USW_TUT2")}</strong></span>}
        subheader=""
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO_TITLE1")}</h3>
      <img src={houseImg} style={{width: "90vw", marginLeft: "5vw"}}/>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("SYS_USW_TUT_TITLE2")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("SYS_USW_TUT_CONTENT3")}.<br />
          （2）{intl.get("SYS_USW_TUT_CONTENT4")}
        </Typography>
      </CardContent>
     </Card>
     <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff',marginBottom:5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅲ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("SYS_USW_TUT3")}</strong></span>}
        subheader=""
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("INTRO_TITLE1")}</h3>
      <img src={houseImg} style={{width: "90vw", marginLeft: "5vw"}}/>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2. {intl.get("SYS_USW_TUT_TITLE2")}</h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("INSTALL_COTENT1")}.<br />
          （2）{intl.get("SYS_USW_TUT_CONTENT3")}
        </Typography>
      </CardContent>
     </Card>
     
     <div style={{textAlign:"center",marginBottom: 70,marginTop:10}}>
      <Button onClick={()=>props.onChangeStep(1)} style={{width:"150px",marginRight:10}} variant="contained" size="large" color="primary">{intl.get("DEVICE_TEST_BTN1")}</Button>
    </div>
  </>;
}
