import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import pcImg from '../img/device.jpeg';
import intl from 'react-intl-universal';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return <>
    <h1 style={{textAlign:"center"}}>{intl.get("DEVICE_TEST_TITLE")}</h1>
    <Card className={classes.root} style={{ width: "100vw", maxWidth: "100vw", backgroundColor: '#fff',marginBottom:5 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Ⅰ
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span style={{ color: "#000", fontSize: 18 }}><strong>{intl.get("DEVICE_TEST_TITLE1")}</strong></span>}
        subheader={intl.get("SYS_USW_TUT_TITLE")}
      />
      <h3 style={{ color: "#000", paddingLeft: 10 }}>1. {intl.get("DEVICE_TEST_CONT1")}</h3>
      <img src={pcImg} style={{ width: "90vw", marginLeft: "5vw" }}></img>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("DEVICE_TEST_CONT2")}<br />
          （2）{intl.get("DEVICE_TEST_CONT3")}
        </Typography>
      </CardContent>
      <CardContent>
        <h3 style={{ color: "#000", paddingLeft: 0 }}>2.{intl.get("DEVICE_TEST_TITLE2")} </h3>
        <Typography variant="body2" color="textSecondary" component="p">
          （1）{intl.get("DEVICE_TEST_CONT4")}<br />
          （2）{intl.get("DEVICE_TEST_CONT5")}
        </Typography>
      </CardContent>
     </Card>
     <div style={{textAlign:"center",marginBottom: 70,marginTop:10}}>
      <Button onClick={()=>props.onChangeStep(0)} style={{width:"150px",marginRight:10}} variant="contained" size="large" color="primary">{intl.get("DEVICE_TEST_BTN1")}</Button>
      <Button onClick={()=>props.onChangeStep(2)} style={{width:"150px"}} variant="contained" size="large" color="primary">{intl.get("DEVICE_TEST_BTN2")}</Button>
    </div>
  </>;
}
